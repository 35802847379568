import routeURLs from 'utils/urls/routeUrls';

import { appModules } from './appModuleConfig';
import { routeDefaultRedirect } from './routeDefaultRedirects';

export const paramsRedirects = {
	[appModules.createTrip]: (param) => {
		const { searchParams, type: subPage = 'result' } = param;
		let redirectURL = false;
		const trip_id = searchParams.get('trip-id');
		const leg_id = searchParams.get('leg-id');
		const selection_id = searchParams.get('selection-id');
		const confirmation_id = searchParams.get('confirmation-id');
		const retry = searchParams.get('retry');
		const from = searchParams.get('ct'); //ct means crete trip page redirection
		const mobile = searchParams.get('mobile');
		const leg_uuid = searchParams.get('leg-uuid');
		const token_id = searchParams.get('token-id');

		switch (subPage) {
			case 'confirmation':
				if (trip_id && (selection_id || confirmation_id)) {
					let retryParam = '';
					if (retry) retryParam = '&retry=true';

					redirectURL = {
						pathname: routeURLs.createTrip.confirmation,
					};
					if (confirmation_id) {
						redirectURL.search = `?trip-id=${trip_id}&confirmation-id=${confirmation_id}${retryParam}`;
					} else if (selection_id) {
						redirectURL.search = `?trip-id=${trip_id}&selection-id=${selection_id}${retryParam}`;
					}
				}
				return { url: redirectURL, showLoader: false };

			case 'cancel-booking':
				if (trip_id) {
					redirectURL = {
						pathname: routeURLs.createTrip.cancelBooking,
					};
					redirectURL.search = `?trip-id=${trip_id}`;
				}
				return { url: redirectURL, showLoader: false };

			case 'reschedule-booking':
				if (trip_id) {
					redirectURL = {
						pathname: routeURLs.createTrip.rescheduleBooking,
					};
					redirectURL.search = `?trip-id=${trip_id}`;
				}
				return { url: redirectURL, showLoader: false };

			case 'cancel-summary':
				if (trip_id && leg_uuid) {
					redirectURL = {
						pathname: routeURLs.createTrip.cancelSummary,
					};
					if (leg_uuid)
						redirectURL.search = `?trip-id=${trip_id}&leg-uuid=${leg_uuid}`;
				}
				return { url: redirectURL, showLoader: false };

			case 'reschedule-summary':
				if (trip_id && leg_uuid) {
					redirectURL = {
						pathname: routeURLs.createTrip.rescheduleSummary,
					};
					if (leg_uuid)
						redirectURL.search = `?trip-id=${trip_id}&leg-uuid=${leg_uuid}`;
				}
				return { url: redirectURL, showLoader: false };

			case 'approvals':
				if (trip_id) {
					redirectURL = {
						pathname: routeURLs.createTrip.approvals,
						search: `?trip-id=${trip_id}${
							token_id ? '&token-id=' + token_id : ''
						}`,
					};
				}
				return { url: redirectURL, showLoader: false };

			default:
				redirectURL = {
					pathname: routeDefaultRedirect(appModules.createTrip),
				};
				let extraParam = '';
				if (trip_id) {
					extraParam = extraParam + (from ? '&ct=1' : '');
					extraParam = extraParam + (mobile ? '&mobile=1' : '');

					redirectURL.search = `?trip-id=${trip_id}${
						leg_id ? '&leg-id=' + leg_id : ''
					}${extraParam}`;
				}
				return {
					url: redirectURL,
					showLoader: true,
					loaderType: from ? 1 : 2,
				};
		}
	},
	[appModules.carServices]: (param) => {
		const { searchParams, type: subPage = 'result' } = param;
		let redirectURL = false;
		const trip_id = searchParams.get('trip-id');
		const car_id = searchParams.get('car-id');
		const service_master_id = searchParams.get('service-master-id');

		switch (subPage) {
			case 'car-booking':
			default:
				if (trip_id && car_id) {
					redirectURL = {
						pathname: routeURLs.carServices.results,
						search: `?trip-id=${trip_id}&car-id=${car_id}&service-master-id=${service_master_id}`,
					};
				}
		}
		return {
			url: redirectURL,
			showLoader: false,
			loaderType: 1,
		};
	},
	[appModules.home]: (param) => {
		const { type: subPage = 'dashboard' } = param;
		let redirectURL = false;

		switch (subPage) {
			case 'dashboard':
			default:
				redirectURL = {
					pathname: routeURLs.dashboard.home,
				};
		}
		return {
			url: redirectURL,
			showLoader: false,
			loaderType: 1,
		};
	},
};
