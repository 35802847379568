import React from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { People } from "icons";

const AnimatedSwitchComp = (props) => {

    const {
        isOn, 
        setIsOn,
        onChange,
        text = "Switch",
        checkedIcon =  <People type="single" color="#6B7280" />,
        unCheckedIcon = <People type="multiple" color="#6B7280"/>
    } = props;

    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 30,
    };

    return (
        <div className="tw-flex tw-flex-col tw-justify-end tw-items-center tw-gap-1">
            <div
                className={`tw-h-7 tw-w-12 tw-bg-color-surface-default-hover tw-flex tw-border tw-border-solid tw-border-color-default tw-items-center ${isOn ? "tw-justify-end" : "tw-justify-start"
                    } tw-rounded-full tw-cursor-pointer`}
                data-ison={isOn}
                onClick={onChange}
            >
                <motion.div
                    className={clsx("tw-w-7 tw-h-7 tw-bg-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-border tw-border-solid tw-border-color-default", {
                        '-tw-mr-[1px]': isOn,
                        '-tw-ml-[1px]': !isOn
                    })}
                    layout
                    transition={spring}
                >
                    {isOn ? checkedIcon : unCheckedIcon}
                </motion.div>
            </div>
            <span className="tw-typography-caption2 tw-text-center tw-w-full tw-text-color-text-subtle">
                Switch role
            </span>
        </div>
    );
};

export const AnimatedSwitch = React.memo(AnimatedSwitchComp);