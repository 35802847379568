import { motion } from 'framer-motion'

const defaultAnimationConfig = {
    initial: {
        y: 70,
        opacity: 0
    },
    animate: {
        y: 0,
        opacity: 1
    },
    transition: {
        duration: 0.3,
        type: 'tween',
        delay: 0.5
    }
}

// Preset animations for common use cases
export const animationPresets = {
    fadeUp: defaultAnimationConfig,
    fadeIn: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: { duration: 0.3 }
    },
    scale: {
        initial: { scale: 0 },
        animate: { scale: 1 },
        transition: { duration: 0.3 }
    },
    slideIn: {
        initial: { x: -100, opacity: 0 },
        animate: { x: 0, opacity: 1 },
        transition: { duration: 0.3 }
    }
}

export const MotionWrapper = ({
    as = 'div',
    children,
    animation = defaultAnimationConfig,
    transition,
    className = '',
    ...props
}) => {
    const Component = motion[as]

    return (
        <Component
            initial={animation.initial}
            animate={animation.animate}
            transition={{...animation.transition, ...transition}}
            className={className}
            {...props}
        >
            {children}
        </Component>
    )
}
